import React, { FC } from 'react';
import styled from 'styled-components';

// @ts-ignore
import profile from '../../static/images/profile.jpg';
import { rem } from '../../utils/converters';
import Text from '../../ui/text/text';
import Emoji from '../emoji/emoji';

const PROFILE_PICTURE_SIZE = 100;

const Container = styled.aside`
  display: flex;
  flex-direction: column;
`;

const ProfilePicture = styled.img`
  width: ${rem(PROFILE_PICTURE_SIZE)};
  height: ${rem(PROFILE_PICTURE_SIZE)};
  border-radius: ${rem(PROFILE_PICTURE_SIZE)};
  font-size: ${rem(10)};
`;

const H1 = styled.h1`
  display: flex;
  flex-direction: column;
`;

const Intro: FC = () => (
  <Container role={'banner'}>
    <ProfilePicture
      alt={'Antoine Quinquenel'}
      src={profile}
      width={rem(PROFILE_PICTURE_SIZE)}
      height={rem(PROFILE_PICTURE_SIZE)}
    />
    <H1>
      <Text size={20}>
        Hi, I&#39;m Antoine&#xa0;Quinquenel.
        {'\xa0'}
        <Emoji emoji={'👋'} label={'Waving hand sign'} size={24} />
      </Text>
      <Text size={20}>
        I&#39;m a software engineer, working mainly with React.
      </Text>
    </H1>
  </Container>
);

export default Intro;
