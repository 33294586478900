import React, { FC } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

import Title from '../../ui/title/title';
import Intro from '../intro/intro';
import TextLink from '../../ui/text-link/text-link';
import { rem } from '../../utils/converters';
import ArticlesList from '../articles-list/articles-list';
import { ArticlePages } from '../../types/article';
import { SitePage } from '../../types/query';

const StyledTitle = styled(Title)`
  padding-top: ${rem(60)};
`;

const Home: FC = () => {
  const data: SitePage & ArticlePages = useStaticQuery(graphql`
    {
      sitePage(
        path: { eq: "/blog/" }
      ) {
        path
        pageContext
      }
      allMdx(
        sort: {
          fields: [frontmatter___date]
          order: DESC
        }
      ) {
        nodes {
          fields {
            slug
          }
          frontmatter {
            title
            description
            tags
            date(formatString: "MMMM YYYY")
          }
        }
      }
    }
  `);

  const {
    sitePage: {
      path,
      pageContext: {
        title,
      },
    },
    allMdx: {
      nodes: articles,
    },
  } = data;

  return (
    <>
      <Intro />
      <StyledTitle>
        <TextLink to={path} size={24}>{title}</TextLink>
      </StyledTitle>
      <ArticlesList articles={articles} />
    </>
  );
};

export default Home;
