import React, { FC } from 'react';

import AppContainer from '../components/app-container/app-container';
import Home from '../components/home/home';

const Index: FC = () => (
  <AppContainer>
    <Home />
  </AppContainer>
);

export default Index;
